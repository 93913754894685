import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { FaFacebook, FaYoutube, FaLinkedin, FaGithub } from 'react-icons/fa'
import FooterListTitle from './FooterListTitle'
import { paymentCard } from '../../../assets/images'
import Image from '../../designLayouts/Image'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [emailInfo, setEmailInfo] = useState('')
  const [subscription, setSubscription] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const emailValidation = () => {
    return String(emailInfo)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/)
  }

  const handleSubscription = () => {
    if (emailInfo === '') {
      setErrMsg('Please provide an Email !')
    } else if (!emailValidation(emailInfo)) {
      setErrMsg('Please give a valid Email!')
    } else {
      setSubscription(true)
      setErrMsg('')
      setEmailInfo('')
    }
  }
  return (
    <div className="w-full bg-[#F5F5F3] py-20">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2  xl:grid-cols-5 px-4 gap-10 justify-items-stretch">
        {/* <div className="col-span-2">
          <FooterListTitle title=" More about Sarry Shop" />
          <div className="flex flex-col gap-6">
            <p className="w-full xl:w-[80%] font-titleFont text-gray-500 ">
              Sarry Shop is your go-to destination for all things fashion and
              style. With a curated collection of trendy clothing, accessories,
              and more, we offer a seamless online shopping experience for
              fashion enthusiasts. Discover the latest trends, shop your
              favorite looks, and elevate your wardrobe with Sarry Shop.
            </p>
            <ul className="flex items-center gap-2">
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaYoutube />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaGithub />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaFacebook />
                </li>
              </a>
              <a href="" target="_blank" rel="noreferrer">
                <li className="w-7 h-7 bg-primeColor text-gray-100 hover:text-white cursor-pointer text-lg rounded-full flex justify-center items-center hover:bg-black duration-300">
                  <FaLinkedin />
                </li>
              </a>
            </ul>
          </div>
        </div> */}
        <div>
          <FooterListTitle title="Shop" />
          <ul className="flex flex-col gap-2">
            <Link to="/shop">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Electronics
              </li>
            </Link>
            <Link to="/shop">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Furniture & Organization
              </li>
            </Link>

            <Link to="/shop">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Home Decor
              </li>
            </Link>

            <Link to="/shop">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Travel & Accessories
              </li>
            </Link>
          </ul>
        </div>
        <div>
          <FooterListTitle title="Your account" />
          <ul className="flex flex-col gap-2">
            <Link to="signin">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Profile
              </li>
            </Link>
            <Link to="signin">
              {' '}
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Orders
              </li>
            </Link>
            <Link to="signin">
              {' '}
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Addresses
              </li>
            </Link>
            <Link to="signin">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Account Details
              </li>
            </Link>
            <Link to="signin">
              <li className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300">
                Payment Options
              </li>
            </Link>
          </ul>
        </div>
        <div className=" grid grid-cols-1 place-content-start">
          <FooterListTitle title="Contact Us" />
          <div className="flex gap-2">
            <h1 className=" font-titleFont text-gray-500">Phone:</h1>
            <a
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300"
              href="tel:850-724-2686"
            >
              850-724-2686
            </a>
          </div>
          <div className=" flex flex-row  w-72">
            <h1 className=" font-titleFont text-gray-500 ">E-mail: </h1>
            <a
              className="font-titleFont text-base text-lightText hover:text-black hover:underline decoration-[1px] decoration-gray-500 underline-offset-2 cursor-pointer duration-300 ml-2"
              href="mailto: support@sarryshop.com"
            >
              support@sarryshop.com
            </a>
          </div>
        </div>
        <div className="col-span-2 flex flex-col items-center w-full px-4">
          <FooterListTitle title="Subscribe to our newsletter." />
          <div className="w-full">
            <p className="text-center mb-4">
              Subscribe to receive newest products!
            </p>
            {subscription ? (
              <motion.p
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full text-center text-base font-titleFont font-semibold text-green-600"
              >
                Subscribed Successfully !
              </motion.p>
            ) : (
              <div className="w-full flex-col xl:flex-row flex justify-between items-center gap-4">
                <div className="flex flex-col w-full">
                  <input
                    onChange={(e) => setEmailInfo(e.target.value)}
                    value={emailInfo}
                    className="w-full h-12 border-b border-gray-400 bg-transparent px-4 text-primeColor text-lg placeholder:text-base outline-none"
                    type="text"
                    placeholder="Insert your email ...*"
                  />
                  {errMsg && (
                    <p className="text-red-600 text-sm font-semibold font-titleFont text-center animate-bounce mt-2">
                      {errMsg}
                    </p>
                  )}
                </div>
                <button
                  onClick={handleSubscription}
                  className="bg-white text-lightText w-[30%] h-10 hover:bg-black hover:text-white duration-300 text-base tracking-wide"
                >
                  Subscribe
                </button>
              </div>
            )}

            <Image
              className={`w-[80%] lg:w-[60%] mx-auto ${
                subscription ? 'mt-2' : 'mt-6'
              }`}
              imgSrc={paymentCard}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
